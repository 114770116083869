.scanner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    pointer-events: none;
    background-color: red;
    opacity: 0;
    transition: opacity .5s ease;
}

.scanner-overlay.scanning {
    opacity: 1;
    animation: animate 4s linear infinite;
}

@keyframes animate {
    0% {
        top: 0;
    }

    25% {
        top: 50%;
    }

    50% {
        top: calc(100% - 5px);
    }

    75% {
        top: 50%;
    }
}
